<template>
  <div class="embedded-bracket">
    <loader v-if="loadingState.bracket" />
    <div v-else-if="hasBracket" ref="bracket">
      <div class="bracket-view">
        <component
          :is="bracketType"
          v-if="bracketType"
          :tournament-id="id"
          :rounds="rounds"
        />
      </div>
    </div>
    <p v-else class="error">
      {{ $t('tournaments.placeholders_bracket') }}
    </p>

    <a
      class="embedded-bracket__watermark"
      :href="tournamentHref"
      target="_blank"
    >
      <Icon name="watermark" group="color" inline />
    </a>
  </div>
</template>

<script>
import Single from '@components/TournamentComponents/Brackets/SingleElimination.vue';
import Double from '@components/TournamentComponents/Brackets/DoubleElimination.vue';
import GroupStageTable from '@components/TournamentComponents/Brackets/GroupStageTable.vue';
import TeamFFA from '@components/TournamentComponents/Brackets/TeamFFA.vue';
import SwissTable from '@components/TournamentComponents/Brackets/SwissTable';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'EmbeddedBracket',
  components: {
    Single,
    Double,
    TeamFFA,
    GroupStageTable,
    SwissTable,
    Icon,
  },
  data: () => ({
    loadingState: {
      bracket: true,
    },
    bracketType: '',
  }),
  computed: {
    ...mapGetters('tournaments', [
      'getTournament',
      'getTournamentBracket',
    ]),

    tournamentHref() {
      return 'https://pvp.vkplay.ru/tournament/' + this.id;
    },
    id() {
      return this.$route.params.id;
    },
    tournament() {
      return this.getTournament(this.id);
    },
    system() {
      return this.tournament?.system?.code;
    },
    rounds() {
      return this.getTournamentBracket(this.id) || [];
    },
    hasBracket() {
      if (
        (_.isArray(this.rounds) && !this.rounds.length) ||
        (this.system === 'double_elim' &&
          !this.rounds.winners.length &&
          !this.rounds.loosers.length)
      ) {
        return false;
      }
      return true;
    },
  },
  async created() {
    await this.fetchTournamentPage(this.id);
    this.fetchTournamentBracket(this.id).finally(() => {
      this.loadingState.bracket = false;
      this.selectBracket();
    });
  },
  methods: {
    ...mapActions('tournaments', [
      'fetchTournamentBracket',
      'fetchTournamentPage',
    ]),

    selectBracket() {
      switch (this.system) {
        case 'single':
          this.bracketType = 'Single';
          break;
        case 'double_elim':
          this.bracketType = 'Double';
          break;
        case 'team_ffa':
          this.bracketType = 'TeamFFA';
          break;
        case 'swiss':
          this.bracketType = 'SwissTable';
          break;
        case 'subgroups':
          this.bracketType = 'GroupStageTable';
          break;
        default:
          throw new Error('No matching bracket types found');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.embedded-bracket {
  position: relative;
  padding: 4px 12px;
}

.embedded-bracket__watermark {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 24px;
  bottom: 24px;
  height: 60px;
  padding: 0px 16px;
  border-radius: 8px;
  background: rgba(45, 45, 48, 0.4);
  backdrop-filter: blur(8px);
  z-index: 1000;
  overflow: hidden;

  font-size: 142px;
  line-height: 0;
}

.bracket {
  &-view {
    display: flex;
    flex-direction: column;
  }
}

.loader {
  margin: 20px auto;
}

.error {
  text-align: center;
  margin-top: 40px;
}
</style>
